import { createSelector } from "reselect";

const selectListing = (state) => state.listingsReducer;

export const selectFeaturedListing = createSelector([selectListing], listings => listings.featured_listings);

export const selectListingLoader = createSelector([selectListing], listings => listings.loading);

export const selectListingDetail = createSelector([selectListing], listings => listings.listing);

export const selectListingCategories = createSelector([selectListing], listings => listings.categories);

export const selectListingCategory = createSelector([selectListing], listings => listings.category);

export const selectListingSubCategories = createSelector([selectListing], listings => listings.sub_categories);

export const selectListings = createSelector([selectListing], listings => listings.listings);


export const selectFetchUserDetail = createSelector([selectListing], listing => listing.user);