// import { Router } from 'react-router-dom';
import { renderRoutes, routes } from './routes/routes';
import {  Router } from "react-router-dom";
import { AuthStateCheck } from './Global/AuthStateCheck';
import Notify from './Global/Notify';
import ScrollToTop from './scrollToTop';


function App({ history }) {
  return ( 
    <Router history={history}> 
    <ScrollToTop>
    <Notify>
        <AuthStateCheck>
          { renderRoutes(routes) } 
        </AuthStateCheck>
      </Notify>
    </ScrollToTop>
      
    </Router>
  );
}
export default App;