export const ListingTypes = {
  FETCH_CARS_FEATURED_LISTING_PROCESS: 'FETCH_CARS_FEATURED_LISTING_PROCESS',
  FETCH_CARS_FEATURED_LISTING_SUCCESS: 'FETCH_CARS_FEATURED_LISTING_SUCCESS',

  FETCH_REAL_ESTATES_FEATURED_LISTING_PROCESS: 'FETCH_REAL_ESTATES_FEATURED_LISTING_PROCESS',
  FETCH_REAL_ESTATES_FEATURED_LISTING_SUCCESS: 'FETCH_REAL_ESTATES_FEATURED_LISTING_SUCCESS',

  FETCH_DEALS_FEATURED_LISTING_PROCESS: 'FETCH_DEALS_FEATURED_LISTING_PROCESS',
  FETCH_DEALS_FEATURED_LISTING_SUCCESS: 'FETCH_DEALS_FEATURED_LISTING_SUCCESS',

  FETCH_JOBS_FEATURED_LISTING_PROCESS: 'FETCH_JOBS_FEATURED_LISTING_PROCESS',
  FETCH_JOBS_FEATURED_LISTING_SUCCESS: 'FETCH_JOBS_FEATURED_LISTING_SUCCESS',

  FETCH_EVENTS_FEATURED_LISTING_PROCESS: 'FETCH_EVENTS_FEATURED_LISTING_PROCESS',
  FETCH_EVENTS_FEATURED_LISTING_SUCCESS: 'FETCH_EVENTS_FEATURED_LISTING_SUCCESS',

  FETCH_SINGLE_LISTING_DETAILS_PROCESS: 'FETCH_SINGLE_LISTING_DETAILS_PROCESS',
  FETCH_SINGLE_LISTING_DETAILS_SUCCESS: 'FETCH_SINGLE_LISTING_DETAILS_SUCCESS',

  FETCH_LISTING_CATEGORIES_PROCESS: 'FETCH_LISTING_CATEGORIES_PROCESS',
  FETCH_LISTING_CATEGORIES_SUCCESS: 'FETCH_LISTING_CATEGORIES_SUCCESS',

  FETCH_LISTING_CATEGORY_PROCESS: 'FETCH_LISTING_CATEGORY_PROCESS',
  FETCH_LISTING_CATEGORY_SUCCESS: 'FETCH_LISTING_CATEGORY_SUCCESS',

  FETCH_LISTING_SUBCATEGORIES_PROCESS: 'FETCH_LISTING_SUBCATEGORIES_PROCESS',
  FETCH_LISTING_SUBCATEGORIES_SUCCESS: 'FETCH_LISTING_SUBCATEGORIES_SUCCESS',

  FETCH_LISTINGS_PROCESS: 'FETCH_LISTINGS_PROCESS',
  FETCH_LISTINGS_SUCCESS: 'FETCH_LISTINGS_SUCCESS',

  FETCH_USER_DETAIL_PROCESS: 'FETCH_USER_DETAIL_PROCESS',
  FETCH_USER_DETAIL_SUCCESS: 'FETCH_USER_DETAIL_SUCCESS'
};