import _ from "lodash";
import ConnectNigeria from "./ConnectNigeria";
// import { formatDate } from "./helpers";

export const requestFetchFeaturedListings = async (listingCategoryId, per_page = 10, page_no = 1) => {
  return await ConnectNigeria.get(`/generic/listing-categories/${listingCategoryId}/listings?include=listingCategory,listingSubCategory,properties,locations&filter[is_featured]=1&per_page=${per_page}&page=${page_no}`);
};

export const requestFetchListingDetail = async (lisingCategoryId, listingId) => {
  return await ConnectNigeria.get(
    `/generic/listing-categories/${lisingCategoryId}/listings/${listingId}?include=owner,likes,listingCategory,listingSubCategory,properties,likes,reviews,gallery,tags,locations`
  );
};

export const requestContactUs = async (contactDetail) => {
  return await ConnectNigeria.post(`/generic/contact-us`, contactDetail);
};

export const requestFetchAllListingCategory = async () => {
  return await ConnectNigeria.get('/generic/listing-categories');
};

export const requestFetchListingCategory = async (listingCategoryId) => {
  return await ConnectNigeria.get(`/generic/listing-categories/${listingCategoryId}?include=properties`);
};

export const requestFetchListingSubCategory = async listingCategoryId => {
  return await ConnectNigeria.get(`/generic/listing-categories/${listingCategoryId}/sub-categories`);
};

export const requestFetchListings = async (listingCategoryId, per_page = 10, page_no = 1, subCategoryId = null) => {
  return await ConnectNigeria.get(`/generic/listing-categories/${listingCategoryId}/listings?include=listingCategory,properties,listingSubCategory,locations&per_page=${per_page}&page=${page_no}${subCategoryId ? `&filter[listing_sub_category_id]=${subCategoryId}` : ''}`);
};

export const requestSearchListing = async (keyword, category, listingCategory, page_no = 1, filterOptions = []) => {
  let propertyQuery = '';
  let location = '';
  
  filterOptions = _.uniqBy(filterOptions.reverse(), 'name');
  
  for(let i = 0; i < filterOptions.length; i++){
    if(filterOptions[i]?.name !== 'location'){
      const formated_date = filterOptions[i]?.value?.replaceAll('/', `-`)

       propertyQuery += `&properties[${filterOptions[i]?.name}]=${  filterOptions[i]?.name.includes('date') ? formated_date : filterOptions[i]?.value }`;
    }else{
      location = filterOptions[i]?.value;
      break;
    }
  }
  listingCategory = listingCategory.replace('-', ' ');

  return await ConnectNigeria.get(`/generic/search?type=listing&${category ? `sub_category=${category}&` : ''}${location ? `location=${location}&` : ''}include=primaryLocation,primaryContact,properties&${keyword ? `keyword=${keyword}&` : ''}page=${page_no}&category=${listingCategory}${propertyQuery}`);     
};

export const requestCreateReview = async(body) => {
  return await ConnectNigeria.post(`user/reviews`, body);
};

export const requestContactSeller = async (body) => {
  return await ConnectNigeria.post(`/generic/contact-us`, body);
};

export const requestLikeAModel = async (body) => {
  return await ConnectNigeria.post(`/user/likes`, body);
};

export const requestFetchListingThatBelongsToABusiness = async (businessId) => {
  return await ConnectNigeria.get(`/generic/businesses/${businessId}/listings?per_page=100`);
};

export const requestFetchUserDetail = async userId => {
  return await ConnectNigeria.get(`/generic/users/${userId}`)
}

export const requestFetchListingThatBelongsToASeller = async (ownerId) => {
  return await ConnectNigeria.get(`/generic/listings?per_page=100&include=listingCategory,properties,owner,locations&filter[owner]=User,${ownerId}`);
};